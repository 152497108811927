<template lang="pug">
k-image.vc-shared-promoter-share-bg-image(
  dimension="100%"
  style-type="product-image"
  :src="promoterMember.bg_image.url"
)
</template>

<script setup>
import { computed } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import kImage from '@sharedComponents/common/k-image.vue'

const store = useStore()
const props = defineProps({
  promoterShare: { type: Object, required: true },
  options: { type: Object }
})

const promoterMember = computed(() => {
  const promoter = store.getters['promoters/find'](
    props.promoterShare.promoter_id
  )

  return store.getters['promoterMembers/find'](promoter.promoter_member_id)
})
</script>
