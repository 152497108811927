<template lang="pug">
k-image.vc-shared-promoter-share-avatar-image(
  style-type="kol-avatar"
  :src="promoterMember.profile.photo.thumb.url"
  :dimension="dimension"
  :options="config"
)
</template>

<script setup>
import { computed } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import kImage from '@sharedComponents/common/k-image.vue'

const store = useStore()
const props = defineProps({
  promoterShare: { type: Object, required: true },
  dimension: { type: String },
  options: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const promoterMember = computed(() => {
  return props.promoterShare.getPromoterMember(store)
})

const promoterLevel = computed(() => {
  const promoterShareLevel = store.getters[
    'promoterShareLevels/findCurrentByShareId'
  ](props.promoterShare.id)

  return store.getters['promoterLevels/find'](promoterShareLevel.level_id)
})

const config = computed(() => {
  return {
    levelDimension: props.options.levelDimension,
    levelImageSrc: promoterLevel.value.image.url
  }
})
</script>
